import { createRouter, createWebHistory } from 'vue-router';

import { useAnalytics, useCard, useEnv, useUser } from '@/composables';
import { useCoreStore, useUserStore } from '@/stores';

const { isTechWorks, appVersion } = useEnv();
const { identify } = useAnalytics();
const logger = useLogger('router', {
  color: '#ffd800',
});

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'HomeIndexPage',
      component: () => import('@/modules/home/pages/HomeIndexPage.vue'),
    },
    {
      path: '/deposit',
      name: 'DepositMethodsIndexPage',
      component: () =>
        import('@/modules/deposit/pages/DepositMethodsIndexPage.vue'),
    },
    {
      path: '/crypto/:network',
      name: 'DepositMethodCryptoIndexPage',
      component: () =>
        import('@/modules/deposit/pages/DepositMethodCryptoIndexPage.vue'),
    },
    {
      path: '/transaction/:id',
      name: 'TransactionIndexPage',
      component: () =>
        import('@/modules/transactions/pages/TransactionIndexPage.vue'),
    },
    {
      path: '/settings',
      name: 'SettingsIndexPage',
      component: () => import('@/modules/settings/pages/SettingsIndexPage.vue'),
    },
    {
      path: '/onboarding',
      name: 'OnboardingIndexPage',
      component: () =>
        import('@/modules/onboarding/pages/OnboardingIndexPage.vue'),
    },
    {
      path: '/card-replace',
      name: 'CardReplaceIndexPage',
      component: () => import('@/modules/card/pages/CardReplaceIndexPage.vue'),
    },

    {
      path: '/:catchAll(.*)',
      redirect: '/',
    },
  ],
});

router.beforeEach(async () => {
  const coreStore = useCoreStore();

  if (coreStore.isReadyForWork) return;

  const userStore = useUserStore();
  const { loadUser } = useUser();
  const { loadCard } = useCard();

  coreStore.isGlobalLoaderShow = true;

  try {
    await loadUser();

    logger.log(
      '*beforeEach()* => loadUser() =>',
      `\nuserId: ${userStore?.user?.id}`,
    );

    identify({
      'App version': appVersion,
      'Telegram ID': userStore?.user?.telegramId,
      'Has card': userStore?.user?.hasCard,
      'Has deposit': userStore?.user?.hasDeposit,
      'Is Verified': userStore?.user?.isVerified,
      'Created at': userStore?.user?.createdAt,
    });

    if (!userStore.user?.isVerified || !userStore.user?.hasCard) return;
    if (isTechWorks) return;

    await loadCard();
  } catch (error) {
    logger.error('*beforeEach()* =>', '\nerror:', error);
    coreStore.$patch({ hasError: true });
  } finally {
    coreStore.$patch({ isReadyForWork: true, isGlobalLoaderShow: false });
  }
});

export default router;
