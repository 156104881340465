import type { Nullable } from '@karta.io/ui-components';

import { type Card, type CardCredentials, CardStatus } from '@/interfaces';

interface CardStoreState {
  isActivated: boolean;
  userCard: Nullable<Card>;
  userCardCredentials: Nullable<CardCredentials>;
}

export const useCardStore = defineStore('cardStore', {
  state: (): CardStoreState => ({
    isActivated: false,
    userCard: null,
    userCardCredentials: null,
  }),
  getters: {
    isLocked(state): boolean {
      if (!state.userCard) return false;
      return state.userCard.status === CardStatus.Paused;
    },
  },
  actions: {
    setCardStatus(status: CardStatus) {
      if (!this.userCard) return;
      this.userCard = { ...this.userCard, status };
    },
  },
});
