/**
 * @todo переписать после рефакторинга бэкенда
 */
import { readUser, updateUser } from '@/api';

import { useCoreStore, useUserStore } from '@/stores';
import type { UserUpdateRequest } from '@/interfaces';

export function useUser() {
  const userStore = useUserStore();
  const coreStore = useCoreStore();

  const load = async () => {
    try {
      const user = await readUser();
      userStore.setUser(user);
    } catch (error) {
      coreStore.$patch({ hasError: true });
      console.error(error);
      throw error;
    }
  };

  const update = async (userData: UserUpdateRequest) => {
    if (!userStore.user) return;

    try {
      const data = await updateUser(userData);

      userStore.setUser({ ...userStore.user, ...data });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    loadUser: load,
    updateUser: update,
  };
}
