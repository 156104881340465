import { createI18n } from 'vue-i18n';
import { vMaska } from 'maska';
import messages from '@intlify/unplugin-vue-i18n/messages';
import { VueTelegramPlugin } from 'vue-tg';

import '@karta.io/ui-components/css/theme-default.css';
import '@karta.io/ui-components/css/main.css';

import '@/assets/styles/main.scss';

import router from '@/router';
import { useAnalytics } from '@/composables';
import {
  DEFAULT_LANGUAGE,
  FALLBACK_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from '@/enums';
import App from './App.vue';

const { initAnalytics } = useAnalytics();

const app = createApp(App);

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  availableLocales: SUPPORTED_LANGUAGES,
  messages,
});

function initApp() {
  app.use(createPinia());

  initAnalytics();

  app.use(i18n);

  app.use(router);
  app.use(VueTelegramPlugin);

  app.directive('maska', vMaska);

  app.mount('#app');
}

initApp();
