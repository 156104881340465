<script setup lang="ts">
import { promiseTimeout } from '@vueuse/core';
import { useWebApp, useWebAppTheme, useWebAppViewport } from 'vue-tg';

import { useCoreStore } from '@/stores';
import { useEnv, useTypedI18n } from '@/composables';

/**
 * Telegram SDK
 */
const { initDataUnsafe } = useWebApp();
const { expand } = useWebAppViewport();
const { setHeaderColor, setBackgroundColor } = useWebAppTheme();

const coreStore = useCoreStore();

const { locale } = useTypedI18n({
  inheritLocale: true,
  useScope: 'global',
});
const { isTechWorks, langCode, isProduction } = useEnv();

localStorage.setItem('debug', String(!isProduction));

const hasError = computed(() => coreStore.hasError);

watch(
  () => coreStore.isGlobalLoaderShow,
  async newValue => {
    if (newValue) {
      document
        ?.querySelector('.app-loader')
        ?.classList.remove('app-loader_hide');
      return;
    }
    /**
     * Небольшой костыль для исправления переключения лейаутов
     * @todo проработать тему с Suspense https://vuejs.org/guide/built-ins/suspense.html
     */
    await promiseTimeout(300);
    document?.querySelector('.app-loader')?.classList.add('app-loader_hide');
  },
  { immediate: true },
);

onMounted(() => {
  expand();
  setHeaderColor('secondary_bg_color');
  setBackgroundColor('secondary_bg_color');

  /**
   * Переключаем язык
   */
  locale.value = (initDataUnsafe?.user?.language_code as 'en-US') || langCode;
});
</script>

<template>
  <NotificationMaintenance v-if="isTechWorks" />

  <NotificationError v-else-if="hasError" />

  <RouterView v-else />
</template>

<style lang="scss">
html,
body,
#app {
  height: 100%;
}

body {
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-base);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}
</style>
