/**
 * @todo рефакторинг transactionsStore
 * Стоит переделать логику хранения параметров
 * limit и ordering точно нет смысла хранить в transactionsStore
 */
import type { Nullable } from '@karta.io/ui-components';

import { getParamsFromUrl } from '@/helpers';

import type { RequestNextParams, Transaction } from '@/interfaces';

interface TransactionsStoreState {
  nextParams: RequestNextParams | null;
  transactions: Transaction[];
}

export const useTransactionsStore = defineStore('transactionsStore', {
  state: (): TransactionsStoreState => ({
    nextParams: {
      limit: '25',
      offset: '0',
      ordering: '-created_at',
    },
    transactions: [],
  }),
  actions: {
    clearAll(): void {
      this.nextParams = {
        limit: '25',
        offset: '0',
        ordering: '-created_at',
      };
      this.transactions = [];
    },
    setTransactionsNext(nextUrl: Nullable<string>): void {
      this.nextParams = getParamsFromUrl<RequestNextParams>(nextUrl);
    },
    expandTransactions(data: Transaction[]): void {
      this.transactions = [...this.transactions, ...data];
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useTransactionsStore, import.meta.hot),
  );
}
