import { http } from '@/api';

import type {
  Card,
  CardCredentials,
  CardReplace,
  CardStatus,
} from '@/interfaces';

const BASE_PATH = 'card/';

export const readCard = (): Promise<Card> => http.get(BASE_PATH);

export const updateCard = (
  status: CardStatus,
): Promise<{ status: CardStatus }> => http.patch(BASE_PATH, { status });

export const readCardCredentials = (): Promise<CardCredentials> =>
  http.get(`${BASE_PATH}credentials/`);

export const readCardReplace = (): Promise<CardReplace> =>
  http.get(`${BASE_PATH}replace/`);

export const createCardSendCodeForReplacement = (): Promise<void> =>
  http.post(`${BASE_PATH}send_code_for_replacement/`);

export const createCardVerifyCodeForReplacement = (
  code: string,
): Promise<void> =>
  http.post(`${BASE_PATH}verify_code_for_replacement/`, { code });
