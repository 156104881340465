import type { Nullable } from '@karta.io/ui-components';

import type { CryptoTokenData } from '@/interfaces';

export enum TransactionStatus {
  Settled = 'SETTLED',
  Pending = 'AUTHORIZED',
  Declined = 'DECLINED',
  Reversed = 'REVERSED',
  Collected = 'COLLECTED',
}

export enum TransactionType {
  CardIssuing = 'CARD_ISSUING',
  CardReplace = 'CARD_REPLACE',
  InboundCrypto = 'INBOUND_CRYPTO',
  CardPayment = 'CARD_PAYMENT',
  CardRefund = 'CARD_REFUND',
}

export enum TransactionFeeType {
  InternationalPayment = 'INTERNATIONAL_PAYMENT',
  CurrencyExchange = 'CURRENCY_EXCHANGE',
  InboundCrypto = 'INBOUND_CRYPTO',
}

export interface TransactionFee {
  type: TransactionFeeType;
  value: string;
  description: string;
}

export interface Transaction {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  authorizedAt: Nullable<Date>;
  totalAmount: string;
  amount: string;
  status: TransactionStatus;
  declineReason: Nullable<string>;
  merchant: Nullable<string>;
  type: TransactionType;
  fee: Nullable<TransactionFee[]>;
  token: Nullable<CryptoTokenData>;
  isCollect: Nullable<boolean>;
  card?: {
    panLast4: string;
    isClosed: boolean;
  };
}

/**
 * @todo Уточнить у бэкенда насчет этих полей
 * половина выглядит абсолютно ненужными
 */
export interface TransactionsReadRequest {
  user: number;
  limit: string;
  offset: string;
  ordering: string;
  created_at__gte: string;
  created_at__lte: string;
  created_at__gt: string;
  created_at__lt: string;
}
