<script lang="ts" setup>
import { IMAGES } from '@/data';
import { useTypedI18n } from '@/composables';

defineOptions({ name: 'NotificationMaintenance' });

const { t } = useTypedI18n({ useScope: 'global' });
</script>

<template>
  <BaseNotification
    :imageSrc="IMAGES.maitenance"
    :title="t('maintenanceNotification.title')"
    :description="t('maintenanceNotification.description')"
  />
</template>
