interface Vars {
  isProduction: boolean;
  isStaging: boolean;
  isDevelopment: boolean;
  isTechWorks: boolean;
  apiBaseUrl: string;
  appVersion: string;
  appEnv: string;
  mixpanelToken: string;
  langCode: string;
  telegramId: string;
}

export function useEnv(): Vars {
  return {
    isProduction: import.meta.env.APP_ENV === 'production',
    isStaging: import.meta.env.APP_ENV === 'staging',
    isDevelopment: import.meta.env.APP_ENV === 'development',
    isTechWorks: import.meta.env.MAINTENANCE_MODE === 'true',
    apiBaseUrl: import.meta.env.API_BASE_URL,
    appVersion: import.meta.env.APP_VERSION,
    appEnv: import.meta.env.APP_ENV,
    mixpanelToken: import.meta.env.MIXPANEL_TOKEN,
    langCode: import.meta.env.LOCAL_TEST_LANG_CODE,
    telegramId: import.meta.env.LOCAL_TEST_TELEGRAM_ID,
  };
}
