<script lang="ts" setup>
import { useCookies } from '@vueuse/integrations/useCookies';

import { UiIconValue } from '@karta.io/ui-components';

import { IMAGES } from '@/data';
import { useTypedI18n } from '@/composables';

defineOptions({ name: 'NotificationError' });

const { getAll, remove } = useCookies();
const { t } = useTypedI18n({ useScope: 'global' });

const isLoading = ref(false);

const handleClick = () => {
  isLoading.value = true;
  const cookies = getAll<Record<string, any>>();

  try {
    Object.keys(cookies).forEach(key => {
      remove(key);
    });
    window.localStorage.clear();
    window.sessionStorage.clear();

    window.location.reload();
  } catch (_error) {
    isLoading.value = false;
  }
};
</script>

<template>
  <BaseNotification
    :imageSrc="IMAGES.error"
    :title="t('errorNotification.title')"
    :description="t('errorNotification.description')"
    :buttonIcon="UiIconValue.Refresh"
    :buttonText="t('errorNotification.button')"
    :isLoading="isLoading"
    @click="handleClick"
  />
</template>
