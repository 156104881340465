interface CoreStoreState {
  isReadyForWork: boolean;
  isGlobalLoaderShow: boolean;
  hasError: boolean;
  hasZeroState: boolean;
}

export const useCoreStore = defineStore('coreStore', {
  state: (): CoreStoreState => ({
    isReadyForWork: false,
    isGlobalLoaderShow: true,
    hasError: false,
    hasZeroState: true,
  }),
});
