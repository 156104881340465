/**
 * @todo вернуться к useCard в рамках рефакторинга
 * нужна обработка ошибок
 * возможно получится перенести в экшены стора
 */
import { UiColor } from '@karta.io/ui-components';
import { useCardStore, useCoreStore } from '@/stores';
import { readCard, readCardCredentials, updateCard } from '@/api';
import { useAnalytics } from '@/composables';

import { AnalyticsTrackName } from '@/enums';
import { CardStatus } from '@/interfaces';

export function useCard() {
  const { track } = useAnalytics();
  const coreStore = useCoreStore();
  const cardStore = useCardStore();
  const logger = useLogger('use-card', {
    color: '#ffd800',
  });

  const isLoading = ref(false);

  const loadCardCredentials = async () => {
    const credentials = await readCardCredentials();
    cardStore.$patch({ userCardCredentials: credentials });
  };

  const load = async () => {
    isLoading.value = true;

    try {
      const card = await readCard();

      if (!card.id) return;

      coreStore.$patch({ hasZeroState: false });
      cardStore.$patch({
        userCard: card,
        isActivated: true,
      });

      /**
       * @todo Убрать, когда карточки будут сразу создаваться на нашей стороне
       * Костыль для перевыпуска карты
       */
      if (card.status === CardStatus.Closed) return;

      await loadCardCredentials();
    } catch (_error) {
      const { error } = _error.response.data;

      logger.error('loadCard error:', error.detail);

      /**
       * @todo Убрать, когда будет рабочий бин под android
       * Костыль для сломанных кредов
       */
      if (error.detail === 'Credentials in progress.') {
        coreStore.$patch({ hasZeroState: false });
        cardStore.$patch({ isActivated: true });

        UiToastNotify({
          title: 'Google pay card notification',
          message: error.detail,
          color: UiColor.Warning,
        });

        return;
      }

      /**
       * @todo переделать, когда будет нормальный responseErrorInterceptor
       * и допилены ошибки в бэке.
       * Пока отправляем ошибки, как есть.
       */
      UiToastNotify({
        title: 'Error',
        message: error.detail,
        color: UiColor.Error,
      });

      coreStore.$patch({ hasError: true });
    } finally {
      isLoading.value = false;
    }
  };

  const update = async (status: CardStatus) => {
    isLoading.value = true;

    try {
      const data = await updateCard(status);
      cardStore.setCardStatus(data.status);

      if (data.status === CardStatus.Active) {
        track({ name: AnalyticsTrackName.CardUnlockSuccess });
      }

      if (data.status === CardStatus.Paused) {
        track({ name: AnalyticsTrackName.CardLockSuccess });
      }
    } catch (_error) {
      coreStore.$patch({ hasError: true });
    } finally {
      isLoading.value = false;
    }
  };

  const lock = () => update(CardStatus.Paused);
  const unlock = () => update(CardStatus.Active);

  return {
    isLoading,

    loadCard: load,
    updateCard: update,
    lock,
    unlock,
  };
}
