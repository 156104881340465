import type { Platform } from '@/enums';

import type {
  CryptoBlockchain,
  CryptoWallet,
  User,
  UserUpdateRequest,
} from '@/interfaces';

interface UserStoreState {
  user: User;
  cryptoWallets: CryptoWallet[];
  platform?: Platform;
}

export const useUserStore = defineStore('userStore', {
  state: (): UserStoreState => ({
    user: {
      id: undefined,
      telegramId: undefined,
      companyId: undefined,
      fullName: '',
      firstName: '',
      lastName: '',
      email: '',
      balance: '',
      createdAt: undefined,
      updatedAt: undefined,
      plan: undefined,
      isActive: false,
      hasDeposit: false,
      hasCard: false,
      kycStatus: undefined,
      isVerified: false,
      platform: undefined,
    },
    cryptoWallets: [],
    platform: undefined,
  }),
  getters: {
    userName: state => {
      return state.user?.firstName && state.user?.lastName
        ? `${state.user!.firstName} ${(state.user?.lastName || 'X')[0]}.`
        : '';
    },
    getWallet: state => (network: CryptoBlockchain) => {
      return (
        state.cryptoWallets?.find(item => item.network === network) ?? null
      );
    },
  },
  actions: {
    setUser(data: UserUpdateRequest): void {
      this.user = {
        ...this.user,
        ...data,
      };
    },
    setUserBalance(balance: string): void {
      if (!this.user) return;

      this.user = {
        ...this.user,
        balance,
      };
    },
    changeUserFullName(newOwner: string): void {
      if (!this.user) return;

      this.user.fullName = newOwner;
    },
  },
});
