import { TransactionStatus } from '@/interfaces';

export const TransactionStatusesMap = {
  [TransactionStatus.Settled]: {
    title: 'Settled',
    classModify: 'settled',
  },
  [TransactionStatus.Pending]: {
    title: 'Pending',
    classModify: 'pending',
  },
  [TransactionStatus.Collected]: {
    title: 'Pending',
    classModify: 'pending',
  },
  [TransactionStatus.Declined]: {
    title: 'Declined',
    classModify: 'declined',
  },
  [TransactionStatus.Reversed]: {
    title: 'Reversed',
    classModify: 'reversed',
  },
  default: {
    title: 'Unknown status',
    classModify: 'pending',
  },
} as const;

export const TransactionsDeclineReasonsMap: Record<string, string> = {
  OTHER: 'The destination could not process this payment',
  DECLINED: 'The destination has declined this payment',
  UNDEFINED: 'The transaction was rejected for an unknown reason',
  NO_ACCOUNT: 'No bank account exists with these account details',
  ACCOUNT_CLOSED: 'The counterparty account is closed',
  ACCOUNT_FROZEN: 'The counterparty account is frozen',
  INVALID_CURRENCY:
    'The destination was unable to process this payment because of its currency',
  CARD_STATUS_INACTIVE: 'Card status is currently inactive',
  INSUFFICIENT_BALANCE: 'Funds not sufficient to complete the transaction',
  REVERSED_BY_MERCHANT: 'Reversed by merchant',
  USER_STATUS_INACTIVE: 'User status is currently inactive',
  CARD_MCC_LIMIT_FAILED: 'Card MCC limit has failed',
  BANK_OWNERSHIP_CHANGED:
    'The destination bank account is no longer valid because of the changes in ownership',
  BUDGET_STATUS_INACTIVE: 'Budget status is currently inactive',
  CARD_YEAR_LIMIT_EXCEED: "You've reached the annual card limit",
  INVALID_ACCOUNT_NUMBER: 'The destination account number is invalid',
  BANK_ACCOUNT_RESTRICTED:
    'The destination bank account has restrictions on either the type or number of transfers allowed',
  CARD_DAILY_LIMIT_EXCEED: "You've reached the daily daily limit",
  OUTBOUND_PAYMENT_FAILED: 'The payment was failed',
  BUDGET_YEAR_LIMIT_EXCEED: "You've reached the annual budget limit",
  CARD_WEEKLY_LIMIT_EXCEED: "You've reached the weekly card limit",
  BUDGET_DAILY_LIMIT_EXCEED: "You've reached the daily budget limit",
  CARD_MONTHLY_LIMIT_EXCEED: "You've reached the monthly card limit",
  COMPANY_STATUS_UNAPPROVED: 'Company status is pending',
  OUTBOUND_PAYMENT_CANCELED: 'The payment was canceled',
  BUDGET_WEEKLY_LIMIT_EXCEED: "You've reached the weekly budget limit",
  CARD_LIFETIME_LIMIT_EXCEED: "You've reached the lifetime card limit",
  CARD_MERCHANT_LIMIT_FAILED: 'Card merchant limit has failed',
  BUDGET_MONTHLY_LIMIT_EXCEED: "You've reached the monthly budget limit",
  BUDGET_LIFETIME_LIMIT_EXCEED: "You've reached the lifetime budget limit",
  BUDGET_USER_MCC_LIMIT_FAILED: 'Budget MCC limit has failed',
  COMPANY_USER_STATUS_INACTIVE: 'Team status is currently inactive',
  DECLINED_BY_CARD_ISSUED_BANK:
    'Card transaction was declined by the issuing bank',
  BUDGET_USER_YEAR_LIMIT_EXCEED: "You've reached the annual budget user limit",
  CARD_TRANSACTION_LIMIT_EXCEED: 'This transaction exceeds your card limit',
  COMPANY_USER_MCC_LIMIT_FAILED: 'User MCC limit has failed',
  INCORRECT_ACCOUNT_HOLDER_NAME: 'The bank account holder name is incorrect',
  BUDGET_USER_DAILY_LIMIT_EXCEED: "You've reached the daily budget user limit",
  COMPANY_USER_YEAR_LIMIT_EXCEED: "You've reached the annual user limit",
  BUDGET_TRANSACTION_LIMIT_EXCEED:
    "You've reached the budget transaction limit",
  BUDGET_USER_WEEKLY_LIMIT_EXCEED:
    "You've reached the weekly budget user limit",
  COMPANY_USER_DAILY_LIMIT_EXCEED: "You've reached the daily user limit",
  BUDGET_USER_MONTHLY_LIMIT_EXCEED:
    "You've reached the monthly budget user limit",
  COMPANY_ACCOUNT_MCC_LIMIT_FAILED: 'Account MCC limit has failed',
  COMPANY_USER_WEEKLY_LIMIT_EXCEED: "You've reached the weekly user limit",
  BUDGET_USER_LIFETIME_LIMIT_EXCEED:
    "You've reached the lifetime budget user limit",
  BUDGET_USER_MERCHANT_LIMIT_FAILED: 'Merchant budget limit has failed',
  CARD_COUNTRY_EXCLUDE_LIMIT_FAILED:
    'Transactions in this country are prohibited',
  CARD_COUNTRY_INCLUDE_LIMIT_FAILED: 'Country is not supported by a card limit',
  COMPANY_ACCOUNT_YEAR_LIMIT_EXCEED: "You've reached the annual account limit",
  COMPANY_USER_MONTHLY_LIMIT_EXCEED: "You've reached the monthly user limit",
  COMPANY_ACCOUNT_DAILY_LIMIT_EXCEED: "You've reached the daily account limit",
  COMPANY_USER_LIFETIME_LIMIT_EXCEED: "You've reached the lifetime user limit",
  COMPANY_USER_MERCHANT_LIMIT_FAILED: 'User merchant limit has failed',
  COMPANY_ACCOUNT_WEEKLY_LIMIT_EXCEED:
    "You've reached the weekly account limit",
  BUDGET_USER_TRANSACTION_LIMIT_EXCEED:
    "You've reached the budget transaction limit",
  COMPANY_ACCOUNT_MONTHLY_LIMIT_EXCEED:
    "You've reached the monthly account limit",
  COMPANY_ACCOUNT_LIFETIME_LIMIT_EXCEED:
    "You've reached the lifetime account limit",
  COMPANY_ACCOUNT_MERCHANT_LIMIT_FAILED: 'Account merchant limit has failed',
  COMPANY_USER_TRANSACTION_LIMIT_EXCEED:
    "You've reached the user transaction limit",
  BUDGET_USER_COUNTRY_EXCLUDE_LIMIT_FAILED:
    'Transactions for this country are prohibited by budget limit',
  BUDGET_USER_COUNTRY_INCLUDE_LIMIT_FAILED:
    'This country is not supported by the budget limit',
  COMPANY_ACCOUNT_TRANSACTION_LIMIT_EXCEED:
    "You've reached the account transaction limit",
  COMPANY_USER_COUNTRY_EXCLUDE_LIMIT_FAILED:
    'Transactions for this country are prohibited by user',
  COMPANY_USER_COUNTRY_INCLUDE_LIMIT_FAILED:
    'The user limit does not support this country',
  COMPANY_ACCOUNT_COUNTRY_EXCLUDE_LIMIT_FAILED:
    'Transactions for this country are prohibited by account limit',
  COMPANY_ACCOUNT_COUNTRY_INCLUDE_LIMIT_FAILED:
    'This country is not supported by account limit',
};
