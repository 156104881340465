import { CryptoBlockchain, CryptoNetwork } from '@/interfaces';

/**
 * @todo Переработать CryptoBlockchains
 * вот здесь абсолютно ненужное разделение на тестовые и не тестовые сети.
 *
 * Вся информация по сетям должна формироваться на стороне бэкенда,
 * в зависимости от окружения.
 *
 * explorer нужно формировать в бэкенде.
 */
export const CryptoBlockchainsMap = {
  [CryptoBlockchain.Tron]: {
    title: 'TRC20',
    titleFull: 'Tron (TRC20)',
    titleLowerCase: 'trc20',
    explorer: 'https://tronscan.org/#/transaction/',
  },
  [CryptoBlockchain.Ethereum]: {
    title: 'ERC20',
    titleFull: 'Ethereum (ERC20)',
    titleLowerCase: 'erc20',
    explorer: 'https://etherscan.io/tx/',
  },

  /**
   * Тестовые сети
   * Goerli - тестовая сеть Ethereum
   * Shasta - тестовая сеть Tron
   * */
  [CryptoBlockchain.Shasta]: {
    title: 'TRC20',
    titleFull: 'Tron (TRC20)',
    titleLowerCase: 'trc20',
    explorer: 'https://shasta.tronscan.org/#/transaction/',
  },
  [CryptoBlockchain.Goerli]: {
    title: 'ERC20',
    titleFull: 'Ethereum (ERC20)',
    titleLowerCase: 'erc20',
    explorer: 'https://goerli.etherscan.io/tx/',
  },
} as const;

export const CryptoNetworkMap = {
  [CryptoNetwork.Trc20]: {
    title: 'TRC20',
    titleFull: 'Tron (TRC20)',
    titleLowerCase: 'trc20',
    explorerLink: 'https://tronscan.org/#/transaction/',
    explorerLinkDev: 'https://shasta.tronscan.org/#/transaction/',
  },
  [CryptoNetwork.Erc20]: {
    title: 'ERC20',
    titleFull: 'Ethereum (ERC20)',
    titleLowerCase: 'erc20',
    explorerLink: 'https://etherscan.io/tx/',
    explorerLinkDev: 'https://goerli.etherscan.io/tx/',
  },
} as const;
