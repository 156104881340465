import { http } from '@/api';

import type { Platform } from '@/enums';
import type {
  User,
  UserUpdateRequest,
  UserUpdateResponse,
  UserVerifyCodeForReplacementCreateRequest,
} from '@/interfaces';

const BASE_PATH = 'user/';

export const readUser = (): Promise<User> => http.get(BASE_PATH);

export const updateUser = (
  data: UserUpdateRequest,
): Promise<UserUpdateResponse> => http.patch(BASE_PATH, data);

export const createUserSendCodeForReplacement = (): Promise<void> =>
  http.post(`${BASE_PATH}send_code_for_change_settings/`);

/**
 * @todo оставить только отправку code, после рефакторинга бэка
 */
export const createUserVerifyCodeForReplacement = (
  data: UserVerifyCodeForReplacementCreateRequest,
): Promise<UserUpdateResponse> =>
  http.post(`${BASE_PATH}verify_code_for_change_settings/`, data);

export const createUserPlatformChange = (data: {
  platform: Platform;
}): Promise<UserUpdateResponse> =>
  http.post(`${BASE_PATH}change_platform/`, data);
