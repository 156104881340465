import Axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';

import { useEnv } from '@/composables';

import {
  requestInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from './interceptors';

const { apiBaseUrl } = useEnv();

export const http = Axios.create({
  baseURL: `${apiBaseUrl}/api/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export type HttpResponse = AxiosResponse;
export type HttpRequestConfig = AxiosRequestConfig;
export type HttpInternalRequestConfig = InternalAxiosRequestConfig;
export type HttpError = AxiosError;

export default http;
