<script lang="ts" setup>
import {
  UiButtonSize,
  UiButtonTheme,
  type UiIconValue,
} from '@karta.io/ui-components';

interface Props {
  imageSrc: string;
  title: string;
  description?: string;
  isLoading?: boolean;
  buttonIcon?: UiIconValue;
  buttonText?: string;
}

defineOptions({ name: 'BaseNotification' });

const props = defineProps<Props>();

const emit = defineEmits<{ click: [] }>();

const cn = useClassName('base-notification');
</script>

<template>
  <div :class="cn.b()">
    <div :class="cn.e('body-wrapper')">
      <div :class="cn.e('image')">
        <img :src="props.imageSrc" :alt="props.title" />
      </div>

      <div :class="cn.e('title')">
        {{ props.title }}
      </div>

      <div v-if="props.description" :class="cn.e('description')">
        {{ props.description }}
      </div>
    </div>

    <div :class="cn.e('footer')">
      <slot name="footer" />
    </div>

    <div v-if="props.buttonText" :class="cn.e('button-wrapper')">
      <UiButton
        :theme="UiButtonTheme.Primary"
        :size="UiButtonSize.Xl"
        :prefixIcon="props.buttonIcon"
        :loading="props.isLoading"
        isFullWidth
        @click="emit('click')"
      >
        {{ props.buttonText }}
      </UiButton>
    </div>
  </div>
</template>

<style lang="scss">
.base-notification {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--spacing-08);
  height: 100vh;
  padding: var(--spacing-03) var(--spacing-04) var(--spacing-04);

  &__body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: var(--spacing-08);
  }

  &__image {
    width: 94px;
    height: 94px;
    margin-bottom: var(--spacing-08);

    img {
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: var(--spacing-02);
    @include app-typography('text-xl-semibold');
    color: var(--text-color);
    text-align: center;
  }

  &__description {
    max-width: 320px;
    @include app-typography('text-md-regular');
    color: var(--hint-color);
    text-align: center;
    font-feature-settings: 'case' on;
  }
}
</style>
