export const DEFAULT_LANGUAGE = 'en';
export const FALLBACK_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'ru'];

export enum SessionStorageKey {
  LastSettingsUpdateTime = '_heycard_last_settings_update_time',
  SettingsNewData = '_heycard_settings_new_data',

  LastCardReplaceTime = '_heycard_last_card_replace_time',
}

/**
 * Названия track событий аналитики
 */
export enum AnalyticsTrackName {
  /**
   * Main page
   */
  WebAppClosed = 'Web app closed',
  ReloadPageClicked = 'Reload page clicked',
  ActivateCardButtonClicked = 'Activate card button clicked',

  /**
   * Onboarding
   */
  OnboardingVerificationPanelShown = 'Onboarding verification panel shown',
  OnboardingVerificationStarted = 'Onboarding verification started',
  OnboardingStepStarted = 'Onboarding step started',
  OnboardingStepCompleted = 'Onboarding step completed',
  OnboardingVerificationPassed = 'Onboarding verification passed',

  /**
   * Menu
   */
  DropdownMenuTargetClicked = 'Kebab menu clicked',
  DropdownMenuSupportClicked = 'Support clicked',
  DropdownMenuSettingsClicked = 'Settings clicked',
  DropdownMenuFaqClicked = 'Faq clicked',

  /**
   * Card
   */
  ReplaceCardClicked = 'Replace card clicked',
  ReplaceCardNotificationShown = 'Replace card notification shown',
  ReplaceCardConfirmationStarted = 'Replace card confirmation started',
  ReplaceCardConfirmationPassed = 'Replace card confirmation passed',
  ReplaceCardConfirmationError = 'Replace card confirmation error',
  ReplaceCardDone = 'Replace card done',

  CardLockClicked = 'Card freeze clicked',
  CardLockSuccess = 'Card freeze success',
  CardUnlockClicked = 'Card unfreeze clicked',
  CardUnlockSuccess = 'Card unfreeze success',

  CardDataShownClicked = 'Card data shown clicked',
  CardDataCopied = 'Card data copied',

  /**
   * Transaction
   */
  TransactionClicked = 'Transaction clicked',

  /**
   * User settings
   */
  UserSettingsChanged = 'User settings changed',
  UserPlatformChanged = 'User platform changed',
}

export enum Platform {
  Ios = 'IOS',
  Android = 'ANDROID',
}
