import mixpanel from 'mixpanel-browser';

import { useUserStore } from '@/stores';
import { useEnv } from '@/composables';

import type { AnalyticsTrackName } from '@/enums';

export function useAnalytics() {
  const { mixpanelToken, isDevelopment, isStaging } = useEnv();

  // нельзя сразу тянуть стор,
  // так как он не успевает установиться при использовании initAnalytics

  let userStore: ReturnType<typeof useUserStore> | null = null;

  const initAnalytics = () => {
    if (!mixpanelToken) return;

    mixpanel.init(mixpanelToken, {
      debug: isDevelopment || isStaging,
      track_pageview: true,
      persistence: 'localStorage',
    });
  };

  const identify = (props?: Record<string, any>) => {
    if (!userStore) userStore = useUserStore();
    if (!userStore.user?.id) return;

    mixpanel.identify(String(userStore.user?.id));

    mixpanel.people.set({
      $name: userStore.user?.fullName,
      $email: userStore.user?.email,
      ...props,
    });
  };

  const track = ({
    name,
    props,
  }: {
    name: AnalyticsTrackName;
    props?: Record<string, any>;
  }): void => {
    if (!mixpanel.get_property('user_id')) identify();
    if (!userStore) userStore = useUserStore();

    mixpanel.track(name, {
      ...props,
    });
  };

  const reset = () => mixpanel.reset();

  return {
    identify,
    track,
    reset,

    initAnalytics,
  };
}
