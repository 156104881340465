export function getParamsFromUrl<T extends { [key: string]: any }>(
  url?: string | null,
): T | null {
  if (!url) return null;

  try {
    let params: T = {};

    const { searchParams } = new URL(url);

    searchParams.forEach((value, key) => {
      params = { ...params, [key]: value };
    });

    return params;
  } catch (_error) {
    return null;
  }
}
