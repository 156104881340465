import type { Platform } from '@/enums';

export enum CardStatus {
  Paused = 'PAUSED',
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

export interface Card {
  id: string;
  billingAddress: string;
  cardHolder: string;
  panLast4: string;
  platform: Platform;
  status: CardStatus;
}

export interface CardCredentials {
  pan: string;
  expiration: string;
  cvc: string;
}

export interface CardReplace {
  fee: string;
  balanceAfterCharge: string;
  cardName: string;
  canReplace: boolean;
  declineReason: string;
}
