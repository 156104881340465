import type { User } from '@/interfaces';

export enum CryptoNetwork {
  Trc20 = 'TRC20',
  Erc20 = 'ERC20',
}

export enum CryptoToken {
  Usdt = 'USDT',
}

export enum CryptoBlockchain {
  Ethereum = 'ETH',
  Tron = 'TRN',

  /**
   * Тестовые сети
   * Goerli - тестовая сеть Ethereum
   * Shasta - тестовая сеть Tron
   * */
  Goerli = 'GETH',
  Shasta = 'STRN',
}

export interface CryptoTokenData {
  network: CryptoBlockchain;
  contractAddress: string;
  name: string;
  symbol: string;
  decimals: number;
  hash: string;
}

/**
 * @todo переименовать поле user в userId на бэке
 */
export interface CryptoWallet {
  id: number;
  address: string;
  network: CryptoBlockchain;
  user: User['id'];
  createdAt: Date;
}

export interface CryptoWalletReadRequest
  extends Pick<CryptoWallet, 'user' | 'network'> {}

export interface CryptoWalletCreateRequest extends CryptoWalletReadRequest {}
