import Axios, {
  type AxiosError,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import WebApp from '@twa-dev/sdk';

import type { ResponseError } from '@karta.io/utils';

import { useCoreStore } from '@/stores';

export function requestInterceptor(config: InternalAxiosRequestConfig) {
  try {
    if (config.params) {
      config.params = snakelizeKeys(config.params);
    }

    if (config.data) {
      config.data = snakelizeKeys(config.data);
    }

    const accessToken = WebApp?.initData
      ? window.btoa(WebApp.initData)
      : import.meta.env.LOCAL_TEST_API_TOKEN;

    if (config.headers) {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }
  } catch (error) {
    console.error('requestInterceptor', error);
  }

  return config;
}

export function responseSuccessInterceptor(
  response: AxiosResponse['data'],
): AxiosResponse['data'] {
  return camelizeKeys(response.data);
}

export function responseErrorInterceptor(
  error: AxiosError<any>,
): Promise<AxiosError<any>> {
  if (Axios.isCancel(error) as boolean) {
    const cancelError: ResponseError = {
      status: 418,
      statusMessage: `I'm a teapot`,
      message: error.message,
      data: null,
    };

    return Promise.reject(cancelError);
  }

  if (error.response?.status !== 404 && error.response?.status !== 400) {
    const coreStore = useCoreStore();

    console.error(`has error with status code: ${error.response?.status}`);

    coreStore.$patch({ hasError: true, isLoading: false });
  }
  return Promise.reject(error);
}
